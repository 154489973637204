// 服务协议
<template>
  <div class="index">
      <ul class="menu">
           <li @click="openMenu('account')">我的资料</li>
            <li @click="openMenu('accountTruthName')">实名认证</li>
            <!-- <li @click="openMenu('accountNews')">消息中心</li> -->
            <li @click="openMenu('accountAddress')">我的地址</li>
            <li @click="openMenu('accountOrder')">我的订单</li>
            <li @click="openMenu('accountCart')">我的购物车</li>
            <li @click="openMenu('accountTuijian')">我的推荐</li>
        </ul>
    <div class="container main">
        <div class="title">收货地址</div>
        <ul class='addressItem'>
            <li class="add" @click="addAddress">
                <i class="el-icon-circle-plus" style="font-size:30px"></i>
                <br>
                <p>添加新地址</p>
            </li>
            <li v-for="item in addressList" :key="item.id">
                <h3> <span>{{item.realName}}</span> <el-tag v-if="item.isDefault"  type='warning' size="medium" effect="plain">默认地址</el-tag></h3>
                <p>{{item.phone}}</p>
                <p>{{item.province}}{{item.city}}{{item.district}}</p>
                <p>{{item.detail}}</p>
                <div class="actions">
                    <div class="actionsItem" @click="deleteAddress(item.id)">删除</div>
                    <div class="actionsItem" @click="editAddress(item)">修改</div>
                    <div class="actionsItem" @click="setDefault(item.id)">设置为默认</div>
                </div>
            </li>
        </ul>
    </div>
    <!-- 添加新地址弹窗 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="showDialog"
      :close-on-click-modal="false"
      custom-class="dialog-minwidth"
      width= "600px"
      :modal-append-to-body = "false"
    >
        <el-form :model="roleForm" :label-position="`left`" label-width="120px" :rules="formRule" ref="roleForm" class="roleForm" >
            <el-form-item label="选择地区:"  prop="role">
                <el-cascader
                v-model="value"
                ref="cascader"
                :options="AreaJson"
                :props="{ expandTrigger: 'hover',value: 'cityId',label: 'name',children: 'child' }"
               ></el-cascader>
            </el-form-item>
            <el-form-item label="详细地址:"  prop="detail">
                <el-input v-model="roleForm.detail" clearable placeholder="请输入收货人详细地址"></el-input>
            </el-form-item>
            <el-form-item label="收货人电话:"  prop="phone">
                <el-input v-model="roleForm.phone" clearable placeholder="请输入收货人电话"></el-input>
            </el-form-item>
            <el-form-item label="收货人姓名:"  prop="realName">
                <el-input v-model="roleForm.realName" clearable placeholder="请输入收货人姓名"></el-input>
            </el-form-item>
            <el-form-item label="是否默认地址:"  prop="isDefault">
                <el-switch v-model="roleForm.isDefault" active-color="#13ce66" inactive-color="#777"></el-switch>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click.native="closeDialog()" class="cancel">取 消</el-button>
            <el-button type="primary" class="save" @click.native="saveRole()" :loading="isSaveLoading">确 定</el-button>
        </div>
    </el-dialog>
  </div>
</template>
<script>
import {AreaJson} from './area.js'
import {apiAddressList,apiAddAddress,apiSetDefault,apiDeleteAddress} from '@/api/account'
export default {
    name: "brand",
    data() {
        return {
            AreaJson,
            addressList: [],
            showDialog: false,
            isSaveLoading: false,
            value:  '',
            formRule:{},
            dialogTitle: '新增收货地址',
            roleForm:{
                address:{},
                detail: '',
                phone: '',
                realName: '',
                isDefault: false
            }
        };
    },
    mounted(){
        this.getAddressList()
    },
    watch:{
        value(){
            if(this.$refs['cascader']){
                let obj =  this.$refs['cascader'].getCheckedNodes()[0]
                console.log(obj);
                this.roleForm.address.province = obj.pathLabels[0]
                this.roleForm.address.city = obj.pathLabels[1]
                this.roleForm.address.district = obj.pathLabels[2]
                this.roleForm.address.cityId = obj.path[1]
            }
            
        }
    },
    methods:{
        openMenu(index){
             console.log(index);
            localStorage.setItem('accountIndex','accountAddress')
            this.$router.push({name: index})
        },
        async getAddressList(){
            const result = await apiAddressList()
            if(result.code == 200){
                this.addressList = result.data.list
            }
        },
        addAddress(){
            this.showDialog = true
            this.roleForm.id = ''
        },
        closeDialog(){
            this.showDialog = false
        },
        saveRole(){
            let params = this.roleForm
            this.isSaveLoading = true
            apiAddAddress(params).then(res=>{
                    if(res.code === 200){
                        this.$message.success('保存成功')
                        this.showDialog = false
                        this.getAddressList()
                    }else{
                        this.$message.warning(res.message)
                    }
                    this.isSaveLoading = false
                }
            )
        },
        deleteAddress(id){
            this.$confirm('确定删除当前选中的收货地址?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    apiDeleteAddress({id}).then(res=>{
                        if(res.code === 200){
                            this.$message.success('删除成功')
                            this.getAddressList()
                        }else{
                            this.$message.warning(res.message)
                        }
                    })
                }).catch(() => {
            });
        },
        setDefault(id){
            this.$confirm('确定将选中的地址设置为默认?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    apiSetDefault({id}).then(res=>{
                        if(res.code === 200){
                            this.$message.success('设置成功')
                            this.getAddressList()
                        }else{
                            this.$message.warning(res.message)
                        }
                    })
                }).catch(() => {
            });
        },
        editAddress(item){
            console.log(item);
            this.addOrEdit = true
            this.showDialog = true
            let obj = []
            this.AreaJson.forEach(items=>{
                items.child.forEach(m=>{
                    if(m.cityId == item.cityId){
                        obj = m.child
                    }
                })
            })
            obj.forEach(m=>{
                if(m.name == item.district ){
                    this.value = m.cityId
                }
            })
            
            this.roleForm = {
                id: item.id,
                address:{province: item.province, city: item.city, district: item.district,cityId: item.cityId,},
                detail: item.detail,
                phone: item.phone,
                realName: item.realName,
                isDefault: item.isDefault
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.index {
  width: 1200px;
  margin: 0 auto;
  background-color: #f5f6f7;
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  .menu{
      width: 200px;
      background-color: #fff;
      li{
          line-height: 60px;
          border-bottom: 1px solid #ccc;
          font-size: 16px;
      }
  }
  .main{
      width: 980px;
      background-color: #fff;
      .title{
        line-height: 50px;
        text-align: left;
        font-size: 16px;
        border-bottom: 1px dashed blue;
        margin-bottom: 20px;
    }
  }
}
.indexBanner,
.indexNotice,
.container {
  background-color: #fff;
  margin-bottom: 10px;
}
.breadcrumb {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  height: 50px;
  padding: 0 20px;
  align-items: center;
  border-bottom: 3px solid#f1f2f3;
}
.medium {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 80px;
}
::v-deep.el-form-item__content{
    .el-cascader{
        width: 100%;
    }
    width: 300px;
    text-align: left;
}
::v-deep.el-dialog__footer{
    padding: 10px 24px;
}
.container{
    padding: 15px 20px;
    .title{
        line-height: 50px;
        text-align: left;
        margin-bottom: 15px;
    }
}
.addressItem{
    display: flex;
     flex-wrap: wrap;
     justify-content: flex-start;
    li{
        width: 30%;
        border: 1px solid #999;
        padding: 25px;
        height: 200px;
        text-align: left;
        margin-right: 20px;
        position: relative;
        h3{
            line-height: 40px;
            font-size: 18px;
            display: flex;
            justify-content: space-between;
        }
        p{
            line-height: 30px;
            font-size: 16px;
        }
        &:hover{
            .actions{
                display: block;
            }
        }
        .actions{
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 40px;
            display: none;
            background-color: #f2f3f4;
            border-top: 1px solid #aaa;
            .actionsItem{
                display: inline-block;
                line-height: 40px;
                width: 33%;
                text-align: center;
                cursor: pointer;
                &:nth-child(2){
                    border-right: 1px solid #aaa;
                    border-left: 1px solid #aaa;
                }
            }
        }
    }
    .add{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
    }
}
</style>